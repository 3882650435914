//
// Industrie Kontor less
// --------------------------------------------------
// Styles directory
// 1. Global
// 1.1 Fonts
// 1.2 Colors
// 1.3 Geometries
// 1.4 Elements
// 2. Navigation
// 3. Sections
// --------------------------------------------------

// 1. Global
// 1.1 Fonts
// font-family: 'Homemade Apple', cursive;
body {
	font-family: "Special Elite";
}
h1, h2, h3 {

}
// 1.2 Colors
// Navi
@color_naviBackgroundUp: #000000; 
@color_naviBackgroundMo: lighten(@color_naviBackgroundUp, 10%);
@color_naviBackgroundSel: #000000; 
@color_naviForegroundUp: #ffffff; 
@color_naviForegroundMo: #c91523; 
@color_naviSocialForegroundUp: #cd1719; 
@color_naviSocialForegroundMo: #050505; 
@color_naviSocialForegroundDo: #050505; 
@color_naviSocialForegroundSel: #050505; 
// Info
@color_info_headline_foreground_color1: #d6b469;
@color_info_headline_foreground_color2: #000000;
// Footer
@color_footer_area_background: #050404;
@color_footer_area_foreground_up: #ffffff;
@color_footer_area_foreground_hover: darken(@color_footer_area_foreground_up, 20%);
@color_footer_area_social_foreground_up: #d6b469;
@color_footer_area_social_foreground_hover: darken(@color_footer_area_foreground_hover, 20%);
@color_footer_bottom_line_background: #2e2c2c;
@color_footer_bottom_line_foreground_up: #ffffff;
@color_footer_bottom_line_foreground_hover: darken(@color_footer_bottom_line_foreground_up, 20%);
//global
@globalAccent_color1: #c91523;
@globalAccent_color1_lighter1: #e51a2a;
@globalAccent_color2: #1d1d1b;
@globalAccent_color3: #ffffff;
@globalBackground_color1: #ffffff;
@globalBackground_color2: #050505;
// 1.3 Geometries
//navi
@navbar_height_xs: 61px;
@global_font_size_xs: 16px;
@global_font_size_xs_small: 14px;
@global_font_size_sm: 14px;
// 1.4 Elements
body {
	overflow-x: hidden;
	color: @globalAccent_color2;
}
a {
	-webkit-transition: color 0.3s, background-color 0.3s;
	-moz-transition: color 0.3s, background-color 0.3s;
	-o-transition: color 0.3s, background-color 0.3s;
	color: @globalAccent_color1;
	&:hover {
		color: darken(@globalAccent_color1, 5%);
	}
}
input.button {
	-webkit-transition: color 0.3s, background-color 0.3s;
	-moz-transition: color 0.3s, background-color 0.3s;
	-o-transition: color 0.3s, background-color 0.3s;
}
.hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
.nopadding {
   padding: 0 !important;
   margin: 0 !important;
}
/***** Navi spy position fix *****/
//body {
//	margin-top: @navbar_height_xs;
//	font-size: @global_font_size_xs;
//	// Small devices (tablets, 768px and up)
//	@media (min-width: @screen-sm-min) {
//		font-size: @global_font_size_sm;
//	}
//	// Medium grid
//	@media (min-width: @screen-md-min) {
//
//	}	
//	// Large grid
//	@media (min-width: @screen-lg-min) {
//
//	}		
//}
//section, footer {
//	padding-top: @navbar_height_xs;
//	margin-top: -@navbar_height_xs;
//}
// 2. Navigation
#navigation {
	height: auto;
	font-size: @global_font_size_xs_small;
	background-color: #000000;
	border-color: #333333;
	// Small devices (tablets, 768px and up)
	@media (min-width: @screen-sm-min) {
		height: @navbar_height_xs;
	}
	// Medium grid
	@media (min-width: @screen-md-min) {

	}	
	// Large grid
	@media (min-width: @screen-lg-min) {

	}
	.container-fluid {
		padding-right: 5px;
	}
	.navbar-header {
		position: relative;
		.navbar-brand {
			position: absolute;
			padding: 0;
			top: -25px;
			img {
				width: 194px;
				height: auto;
			}
		}
		.navi-logo-image {
			display: inline-block;
			padding-top: 5px;
			padding-bottom: 5px;
			&:hover {
				img {
					opacity: .9;
				}
			}
		}
	}
	.navbar-toggle {
		border-color: @globalAccent_color1;
		margin-top: 12px;
		.icon-bar {
			background-color: @globalAccent_color1;
		}
	}
	#navbarcollapseitems {
		max-height: 500px;
		ul.navbar-nav.navi-links {
			float: none;
			// Small devices (tablets, 768px and up)
			@media (min-width: @screen-sm-min) {
				float: left;
			}
			// Medium grid
			@media (min-width: @screen-md-min) {

			}	
			// Large grid
			@media (min-width: @screen-lg-min) {

			}			
			li {
				a {
					text-transform: uppercase;
					font-weight: bold;
					font-size: 20px;
					color: @color_naviForegroundUp;
					text-align: center;
					padding-top: 15px;
					padding-bottom: 10px;
					&:hover {
						color: @color_naviForegroundMo;
						background-color: @color_naviBackgroundMo;
					}
					&.active {
						color: @color_naviForegroundMo;
						background-color: @color_naviBackgroundSel;
					}
					// Small devices (tablets, 768px and up)
					@media (min-width: @screen-sm-min) {
						font-size: 12px;
						padding-left: 5px;
						padding-right: 5px;
						padding-top: 22px;
						padding-bottom: 18px;
						text-align: left;						
					}
					// Medium grid
					@media (min-width: @screen-md-min) {
						font-size: 14px;
						padding-left: 10px;
						padding-right: 10px;							
					}	
					// Large grid
					@media (min-width: @screen-lg-min) {
						font-size: 20px;
						padding-left: 10px;
						padding-right: 10px;						
					}	
					// Very Large grid
					@media (min-width: 1400px) {
						font-size: 20px;
						padding-left: 22px;
						padding-right: 22px;						
					}					
				}
			}
		}
		ul.navbar-nav.shop-link {
			margin-left: 0;
			// Small devices (tablets, 768px and up)
			@media (min-width: @screen-sm-min) {
				float: right;
				margin-left: 20px;
				margin-right: 50px;
			}
			// Medium grid
			@media (min-width: @screen-md-min) {

			}	
			// Large grid
			@media (min-width: @screen-lg-min) {

			}
			li {
				width: calc(100% - 15px);
				// Small devices (tablets, 768px and up)
				@media (min-width: @screen-sm-min) {
					width: 100%;
				}
				// Medium grid
				@media (min-width: @screen-md-min) {

				}	
				// Large grid
				@media (min-width: @screen-lg-min) {

				}
				a {
					width: auto;
					height: auto;
					background-color: @globalAccent_color1;
					color: #ffffff;
					font-size: 24px;
					text-align: center;
					display: block;
					padding-top: 15px;
					padding-bottom: 10px;
					text-transform: uppercase;
					&:hover {
						color: #000000;
					}
					// Small devices (tablets, 768px and up)
					@media (min-width: @screen-sm-min) {
						width: 100px;
						height: 77px;
						background-color: transparent;
						background-image: url("../assets/theme/Shop.png");
						background-size: cover;
						background-position: center center;
						background-repeat: no-repeat;
						font-size: 25px;
						display: inline-block;
						padding-top: 23px;
					}
					// Medium grid
					@media (min-width: @screen-md-min) {
						width: 120px;
						height: 93px;
						background-image: url("../assets/theme/Shop.png");
						background-size: cover;
						background-position: center center;
						background-repeat: no-repeat;
						font-size: 32px;
						display: inline-block;
						padding-top: 31px;
					}	
					// Large grid
					@media (min-width: @screen-lg-min) {
						width: 153px;
						height: 118px;
						background-image: url("../assets/theme/Shop.png");
						font-size: 42px;
						display: inline-block;
						padding-top: 37px;
					}
				}
			}
		}
		.language-switch {
			float: none;
			// Small devices (tablets, 768px and up)
			@media (min-width: @screen-sm-min) {
				float: right;
			}
			@media (max-width: @screen-sm-min) {
				height: 62px;
			}
			a.dropdown-toggle {
				display: none;
				background-image: url("../assets/theme/language.png");
				background-position: 140px 10px;
				background-repeat: no-repeat;
				padding-right: 50px;
				-webkit-transition: color 0s, background-color 0s;
				-moz-transition: color 0s, background-color 0s;
				-o-transition: color 0s, background-color 0s;
				// Small devices (tablets, 768px and up)
				@media (min-width: @screen-sm-min) {
					display: block;
					background-position: center 10px;
					width: 55px;
				}
				// Medium grid
				@media (min-width: @screen-md-min) {
					background-position: 89px 10px;
					width: auto;
				}	
				// Large grid
				@media (min-width: @screen-lg-min) {
					background-position: 119px 10px;
				}
				span {
					// Medium grid
					@media (max-width: @screen-md-min) {
						display: none;
					}
				}
				&:hover {
					background-image: url("../assets/theme/language_hover.png");
				}		
			}
			a {
				display: inline-block;
				font-size: 20px;
				color: @color_naviForegroundUp;
				padding-left: 5px;
				padding-right: 5px;
				padding-top: 19px;
				text-align: left;
				font-weight: bold;
				// Small devices (tablets, 768px and up)
				@media (min-width: @screen-sm-min) {
					font-size: 12px;				
					padding-top: 22px;
					padding-bottom: 18px;					
				}
				// Small devices (tablets, 768px and up)
				@media (min-width: @screen-sm-min) {
					font-size: 14px;
					padding-left: 0;
					padding-right: 0;
				}
				// Medium grid
				@media (min-width: @screen-md-min) {
					font-size: 14px;
					padding-left: 10px;
					padding-right: 5px;
				}	
				// Large grid
				@media (min-width: @screen-lg-min) {
					font-size: 20px;
					padding-left: 10px;
				}
				&:hover {
					color: @color_naviForegroundMo;
				}
				&.active-lang {
					color: @color_naviForegroundMo;
				}
			}
			li.dropdown {
				text-align: center;
				&.open {
					& > a, & > a:hover, & > a:focus {
						background-color: #020202;
						background-image: url("../assets/theme/language_hover.png");
						// Small devices (tablets, 768px and up)
						@media (min-width: @screen-sm-min) {
							float: right;

						}
						// Medium grid
						@media (min-width: @screen-md-min) {

						}	
						// Large grid
						@media (min-width: @screen-lg-min) {

						}
					}
				}
				ul.dropdown-menu {
					border-width: 2px 2px 2px 2px;
					border-color: @globalAccent_color1;
					right: 0;
					left: auto;
					padding: 0;
					min-width: 0;
					@media (max-width: @screen-sm-min) {
						display: block;
						width: 100%;
						border-width: 0;
						background-color: #020202;
					}
					// Small devices (tablets, 768px and up)
					@media (min-width: @screen-sm-min) {
						float: right;
						width: 150px;
					}
					// Medium grid
					@media (min-width: @screen-md-min) {
						width: 160px;
					}	
					// Large grid
					@media (min-width: @screen-lg-min) {
						width: 180px;
					}					
					li {
						@media (max-width: @screen-sm-min) {
							width: 33%;
							float: left;
							a {
								text-align: center;
							}
						}
						a {
							background-color: #020202;
							width: 100%;
							padding-left: 10px;
							@media (max-width: @screen-sm-min) {
								padding-bottom: 18px;
							}
							// Small devices (tablets, 768px and up)
							@media (min-width: @screen-sm-min) {
								width: 146px;
							}
							// Medium grid
							@media (min-width: @screen-md-min) {
								width: 156px;
							}	
							// Large grid
							@media (min-width: @screen-lg-min) {
								width: 176px;
							}	
						}
					}
				}
			}
			// Small devices (tablets, 768px and up)
			@media (min-width: @screen-sm-min) {
				float: right;
			}
			// Medium grid
			@media (min-width: @screen-md-min) {

			}	
			// Large grid
			@media (min-width: @screen-lg-min) {

			}
		}
	}
}
#teasers {
	position: relative;
	z-index: 200;
	margin-top: @navbar_height_xs;
	.container-fluid {
		background-image: url("../assets/theme/start_bg_01.png");
		background-repeat: no-repeat;
		background-position: bottom center;
		background-size: cover;
		.teaser-row {
			transition: transform .3s;
			&:hover {
				transform: scale(1.02);
			}
			margin-top: 20px;
			// Small devices (tablets, 768px and up)
			@media (min-width: @screen-sm-min) {
				margin-top: 50px;
			}
			// Medium grid
			@media (min-width: @screen-md-min) {
				margin-top: 100px;
			}	
			// Large grid
			@media (min-width: @screen-lg-min) {

			}
			img {
				width: 100%;
				height: auto;
				margin-bottom: 50px;
				// Small devices (tablets, 768px and up)
				@media (min-width: @screen-sm-min) {
					width: 100%;
				}
				// Medium grid
				@media (min-width: @screen-md-min) {

				}	
				// Large grid
				@media (min-width: @screen-lg-min) {

				}
			}
			div {
				text-align: center;
			}
			div:nth-child(1) {
				// Small devices (tablets, 768px and up)
				@media (min-width: @screen-sm-min) {
					width: 38%;
				}
				// Medium grid
				@media (min-width: @screen-md-min) {

				}	
				// Large grid
				@media (min-width: @screen-lg-min) {

				}
			}			
			div:nth-child(2) {
				// Small devices (tablets, 768px and up)
				@media (min-width: @screen-sm-min) {
					width: 24%;
				}
				// Medium grid
				@media (min-width: @screen-md-min) {

				}	
				// Large grid
				@media (min-width: @screen-lg-min) {

				}
			}			
			div:nth-child(3) {
				// Small devices (tablets, 768px and up)
				@media (min-width: @screen-sm-min) {
					width: 38%;
				}
				// Medium grid
				@media (min-width: @screen-md-min) {

				}	
				// Large grid
				@media (min-width: @screen-lg-min) {

				}
			}
		}
		.feature-row {
			margin-bottom: 100px;
			img {
				width: 100%;
				margin-bottom: 30px;
				transition: transform .3s;
				&:hover {
					transform: scale(1.02);
				}
			}
		}
	}
}
#toasts {
	position: relative;
	z-index: 100;
	.container-fluid {
		background-image: url("../assets/theme/start_bg_02.jpg");
		background-repeat: no-repeat;
		background-position: bottom center;
		background-size: cover;
		padding-top: 200px;
		padding-bottom: 200px;
		margin-top: -150px;
		p {
			text-align: center;
			font-size: 24px;
			color: #ffffff;
			margin-bottom: 30px;
			span {
				font-size: 16px;
			}
		}
	}
}
#good-reasons {
	position: relative;
	z-index: 200;
	.container-fluid {
		background-image: url("../assets/theme/start_bg_03.png");
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		padding-top: 100px;
		padding-bottom: 100px;
		margin-top: -150px;
		color: #ffffff;
		// Small devices (tablets, 768px and up)
		@media (min-width: @screen-sm-min) {
			
		}
		// Medium grid
		@media (min-width: @screen-md-min) {
			background-size: 100% 100%;
		}	
		// Large grid
		@media (min-width: @screen-lg-min) {

		}
	}
	h1 {
		font-family: 'Homemade Apple', cursive;
		font-size: 42px;
		text-align: center;
		margin-top: 30px;
		margin-bottom: 50px;
	}
	img {
		width: 80%;
		height: auto;
		margin: 0 auto;
		display: block;
		margin-top: 50px;
	}
	ol {
		font-family: 'Fredericka the Great', cursive;
		font-size: 72px;
		padding-left: 85px;
		margin-right: 0;
		p {
			font-family: 'Special Elite', cursive;
			font-size: 16px;
			vertical-align: top;
			display: inline-block;
			margin-top: 40px;
			margin-right: 15px;
			span {
				font-size: 20px;
			}
		}
	}
	.img-buy-now {
		float: none;
		width: 80%;
		// Small devices (tablets, 768px and up)
		@media (min-width: @screen-sm-min) {
			float: right;
			width: 70%;
		}
		// Medium grid
		@media (min-width: @screen-md-min) {

		}	
		// Large grid
		@media (min-width: @screen-lg-min) {

		}
	}
}
#illustration {
	position: relative;
	z-index: 100;
	.container-fluid {
		margin-top: -100px;
		padding: 0;
		// Small devices (tablets, 768px and up)
		@media (min-width: @screen-sm-min) {
			margin-top: -50px;
		}
		// Medium grid
		@media (min-width: @screen-md-min) {

		}	
		// Large grid
		@media (min-width: @screen-lg-min) {

		}
		img {
			width: 100%;
			height: auto;
		}
	}
}
.o-start-page {
	&-eventsSlider {
		&__section {
			position: relative;
			h2 {
				color: #ffffff;
				text-align: center;
				text-transform: uppercase;
				margin-bottom: 30px;
			}
			.container-fluid {
				background-image: url("../assets/theme/concrete_bg_04.jpg");
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;	
				padding-bottom: 70px;
				padding-top: 40px;
				padding-left: 45px;
				padding-right: 45px;
				// Small devices (tablets, 768px and up)
				@media (min-width: @screen-sm-min) {

				}
				// Medium grid
				@media (min-width: @screen-md-min) {
					
				}	
				// Large grid
				@media (min-width: @screen-lg-min) {

				}				
			}
		}
		&__outerContainer {
			.slick-prev.slick-arrow {
				position: absolute;
				top: 0;
				left: -45px;
				text-indent: -9999px;
				white-space: nowrap;
				overflow: hidden;
				width: 45px;
				height: 100%;
				background-color: transparent;
				border-width: 0;
				background-image: url("../assets/theme/slider_button_prev.png");
				background-repeat: no-repeat;
				background-position: center;
				&:hover {
					opacity: .9;
				}
			}
			.slick-next.slick-arrow {
				position: absolute;
				top: 0;
				right: -45px;
				text-indent: -9999px;
				white-space: nowrap;
				overflow: hidden;
				width: 45px;
				height: 100%;
				background-color: transparent;
				border-width: 0;
				background-image: url("../assets/theme/slider_button_next.png");
				background-repeat: no-repeat;
				background-position: center;
				&:hover {
					opacity: .9;
				}
			}
		}
		&__item {
			width: 60vw;
			max-width: 400px;
			height: auto;
			margin: 0 8px;
			// Small devices (tablets, 768px and up)
			@media (min-width: @screen-sm-min) {
				width: 400px;
			}
			// Medium grid
			@media (min-width: @screen-md-min) {

			}	
			// Large grid
			@media (min-width: @screen-lg-min) {

			}
		}
	}
}
.card-event {
	&__outerContainer {
		a {
			display: block;
			background-color: #e6e6e6;
			transition: transform .3s;
			padding-bottom: 5px;
			margin-bottom: 30px;
			-webkit-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.55);
			-moz-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.55);
			box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.55);
			&:hover {
				transform: scale(1.02);
			}
			h3 {
				display: inline-block;
				margin-top: 9px;
				font-family: 'Roboto Slab', serif;
				font-weight: bold;
				text-transform: uppercase;
				word-wrap: break-word;
				padding-left: 15px;
				padding-right: 15px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 100%;
			}
			img {
				width: 100%;
			}
			p.event-description {
				color: #999999;
			}
			p {
				margin-left: 15px;
				margin-top: 17px;
				img {
					display: inline-block;
					width: 6px;
					height: auto;
					vertical-align: baseline;
				}
				span {
					word-wrap: break-word;
				}
			}
			&:hover p.event-description {
				text-decoration: none;
			}
		}
	}
}
footer {
	position: relative;
	color: #ffffff;
	a {
		color: #ffffff;
	}
	.container-fluid {
		background-color: #030303;
		padding-top: 50px;
		padding-bottom: 50px;
	}
	ul {
		list-style: none;
		margin-bottom: 30px;
	}
	.footer-links-1 {
		text-transform: uppercase;
		float: left;
		margin-right: 20px;
		// Small devices (tablets, 768px and up)
		@media (min-width: @screen-sm-min) {
			
		}
		// Medium grid
		@media (min-width: @screen-md-min) {

		}	
		// Large grid
		@media (min-width: @screen-lg-min) {

		}
	}
	.footer-links-2 {
		text-transform: uppercase;
		float: left;
		margin-right: 20px;		
		// Small devices (tablets, 768px and up)
		@media (min-width: @screen-sm-min) {
			margin-right: 40px;	
		}
		// Medium grid
		@media (min-width: @screen-md-min) {

		}	
		// Large grid
		@media (min-width: @screen-lg-min) {

		}
	}
	.footer-links-2a {
		float: left;
		margin-right: 20px;			
		display: grid;
		// Small devices (tablets, 768px and up)
		@media (min-width: @screen-sm-min) {
			float: none;
		}
		// Medium grid
		@media (min-width: @screen-md-min) {

		}	
		// Large grid
		@media (min-width: @screen-lg-min) {

		}
	}
	.footer-links-3 {
		// Small devices (tablets, 768px and up)
		@media (min-width: @screen-sm-min) {
			float: left;
			margin-right: 20px;
		}
		// Medium grid
		@media (min-width: @screen-md-min) {

		}	
		// Large grid
		@media (min-width: @screen-lg-min) {

		}
	}
	.footer-links-4 {
		// Small devices (tablets, 768px and up)
		@media (min-width: @screen-sm-min) {

		}
		// Medium grid
		@media (min-width: @screen-md-min) {
			margin-left: 0;
		}	
		// Large grid
		@media (min-width: @screen-lg-min) {
			margin-left: 50px;
		}
	}
	.flex_footer_2 {
		img {
			display: block;
			margin: 0 auto;
			margin-bottom: 50px;
		}
	}
	.thebird {
		position: absolute;
		top: -90px;
		right: 20vw;
		height: 100px;
	}
}
/*common stuff*/
/*section*/
.o-section {
	margin-top: @navbar_height_xs;
	&--team {
		background-image: url("../assets/theme/concrete_bg_01.jpg");
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-size: cover;
		background-position: center;
	}
}
/*page*/
.o-page {
	&__title {
		font-family: 'Fredericka the Great', cursive !important;
		font-size: 60px;
		text-align: center;
		&--1 {
			color: @globalAccent_color1;
		}
	}
	&-wideHeader {
		&__outerContainer {
			background-position: center;
			background-size:cover;
			padding: 170px 15px 20px 15px;
			text-align: center;
			// Small devices (tablets, 768px and up)
			@media (min-width: @screen-sm-min) {
				padding: 150px 15px 180px 15px;
			}
			// Medium grid
			@media (min-width: @screen-md-min) {
				padding: 200px 100px 230px 100px;
			}	
			// Large grid
			@media (min-width: @screen-lg-min) {

			}
			&--start {
				background-image: url("../assets/start/header.jpg");
				h2 {
					text-transform: uppercase;
				}
			}
			&--partners {
				background-image: url("../assets/partners/header.jpg");
			}
			&--404 {
				background-image: url("../assets/404/header.jpg");
			}
			&--events {
				background-image: url("../assets/events/header.jpg");
			}
			&--team {
				background-image: url("../assets/team/header.jpg");
			}
			&--imprint {
				background-image: url("../assets/imprint/header.jpg");
			}
			&--faq {
				background-image: url("../assets/faq/header.jpg");
			}
			&--factory {
				background-image: url("../assets/factory/bg_wachtigall_fabrik_3.jpg");
				// Small devices (tablets, 768px and up)
				@media (min-width: @screen-sm-min) {
					padding: 150px 15px 180px 15px;
				}
				// Medium grid
				@media (min-width: @screen-md-min) {
					padding: 200px 100px 230px 100px;
					height: calc(100vh - @navbar_height_xs);
				}	
				// Large grid
				@media (min-width: @screen-lg-min) {

				}				
			}
		}
		&__header {
			font-family: "Special Elite";
			font-size: 36px;
			text-align: center;
			margin-left: auto;
			margin-right: auto;			
			background-color: rgba(0,0,0,0.8);
			color: @globalAccent_color1;
			padding: 15px;
			// Small devices (tablets, 768px and up)
			@media (min-width: @screen-sm-min) {
				display: inline-block;
				font-size: 72px;
				padding: 20px 20px 0 20px;
			}
			// Medium grid
			@media (min-width: @screen-md-min) {

			}	
			// Large grid
			@media (min-width: @screen-lg-min) {

			}
		}
	}
	&-introText {
		&__outerContainer {
			&--start {
				background-image: url("../assets/theme/concrete_bg_02.jpg");
				background-size: cover;
				background-position: center;
			}
			&--partners {
				background-image: url("../assets/partners/intro_background.jpg");
				background-size: cover;
				background-position: bottom center;
			}
			&--404 {
				background-image: url("../assets/theme/wachtigall_bg_parchment.jpg");
				background-size: cover;
				background-position: top center;
			}
			&--imprint {
				background-color: #ffffff;
			}
		}
		&__textContainer {
			&--start {
				text-align: center;
				padding: 90px 0 25px 0;
				// Small devices (tablets, 768px and up)
				@media (min-width: @screen-sm-min) {
					padding: 90px 0 50px 0;
				}
				// Medium grid
				@media (min-width: @screen-md-min) {
					padding: 90px 0 50px 0;
				}	
				// Large grid
				@media (min-width: @screen-lg-min) {
					padding: 90px 90px 50px 90px;
				}				
			}
			&--partners {
				padding: 90px 0 0 0;
				// Small devices (tablets, 768px and up)
				@media (min-width: @screen-sm-min) {
					background-image: url("../assets/partners/illu_statistics.png");
					background-size: 196px;
					background-repeat: no-repeat;				
					background-position: right 70px;
				}
				// Medium grid
				@media (min-width: @screen-md-min) {
					padding: 90px 0 240px 0;
					margin: 0 0 60px 0;
					background-size: 160px;
					background-position: right bottom;
				}	
				// Large grid
				@media (min-width: @screen-lg-min) {
					background-position: right 70px;
					padding: 90px 0 300px 0;
					margin: 0;
					background-size: 196px;
				}	
			}
			&--404 {
				background: none;
				padding: 90px 0 0 0;
				// Small devices (tablets, 768px and up)
				@media (min-width: @screen-sm-min) {

				}
				// Medium grid
				@media (min-width: @screen-md-min) {
					padding: 90px 0 90px 0;
				}	
				// Large grid
				@media (min-width: @screen-lg-min) {
					
				}
			}
			&--imprint {
				background: none;
				padding: 90px 0 90px 0;
				// Small devices (tablets, 768px and up)
				@media (min-width: @screen-sm-min) {

				}
				// Medium grid
				@media (min-width: @screen-md-min) {
					padding: 90px 0 90px 0;
				}	
				// Large grid
				@media (min-width: @screen-lg-min) {
					
				}
			}
		}
		&__header {
			text-transform: uppercase;
		}
		&__body {
			margin-top: 20px;
			font-size: 18px;
			&--emphasizedText {
				color: @globalAccent_color1;
			}
		}
		&__imageContainer {
			&--start {
				padding-bottom: 90px;
				text-align: center;
				a {
					font-size: 36px;
					background-color: transparent;
					display: block;	
					margin: 0 auto;
					img {
						width: 100%;
					}
					-webkit-transition: width 0.3s;
					-moz-transition: width 0.3s;
					-o-transition: width 0.3s;
					transition: width 0.3s;
					width: 95%;
					&:hover {
						width: 100%;
					}
				}
			}
			&--partners {
				background-image: url("../assets/partners/illu_explanation.png");
				background-size: 340px;
				background-repeat: no-repeat;
				background-position: center bottom;
				padding: 50px 0 250px 0;
				margin: 0 0 100px;
				// Small devices (tablets, 768px and up)
				@media (min-width: @screen-sm-min) {
					background-size: 380px;
				}
				// Medium grid
				@media (min-width: @screen-md-min) {
					padding: 90px 0 250px 0;
					margin: 0 0 50px;
				}	
				// Large grid
				@media (min-width: @screen-lg-min) {
					padding: 90px 0 200px 0;
					margin: 0 0 100px;
					background-size: 340px;
				}
			}	
			&--404 {
				padding: 50px 0 90px 0;
				// Small devices (tablets, 768px and up)
				@media (min-width: @screen-sm-min) {
					
				}
				// Medium grid
				@media (min-width: @screen-md-min) {
					padding: 90px 0 90px 0;
				}	
				// Large grid
				@media (min-width: @screen-lg-min) {

				}
			}
			&--imprint {
				padding: 50px 0 0 0;
				text-align: center;
				// Small devices (tablets, 768px and up)
				@media (min-width: @screen-sm-min) {
					
				}
				// Medium grid
				@media (min-width: @screen-md-min) {
					padding: 90px 0 90px 0;
				}	
				// Large grid
				@media (min-width: @screen-lg-min) {

				}
				img {
					width: 90%;
					// Small devices (tablets, 768px and up)
					@media (min-width: @screen-sm-min) {
						
					}
					// Medium grid
					@media (min-width: @screen-md-min) {
						width: auto;
					}	
					// Large grid
					@media (min-width: @screen-lg-min) {

					}
				}
			}			
		}		
		&__image {
			display: block;
			margin: 0 auto;
			width: 80%;
			height: auto;			
			// Small devices (tablets, 768px and up)
			@media (min-width: @screen-sm-min) {
				width: auto;
				height: auto;
			}
			// Medium grid
			@media (min-width: @screen-md-min) {
				display: inline-block;
			}	
			// Large grid
			@media (min-width: @screen-lg-min) {

			}
		}
	}
	&-citationsTeaser {
		&__outerContainer {
			background-image: url("../assets/start/citations_bg01.jpg");
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			padding-top: 50px;
			padding-bottom: 50px;
			p {
				text-align: center;
				font-size: 24px;
				color: #ffffff;
				span {
					font-size: 16px;
				}
			}			
		}
	}
	&-goodReasons {
		&__outerContainer {
			position: relative;
			background-image: url("../assets/theme/concrete_bg_03.jpg");
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			padding-top: 50px !important;
			padding-bottom: 50px !important;	
			color: #ffffff;
			h2 {
				font-family: 'Special Elite', cursive;
				font-size: 56px;
				text-align: center;
				margin-top: 30px;
				margin-bottom: 50px;
			}
			img {
				width: 100%;
				height: auto;
				margin: 0 auto;
				display: block;
			}
			ol {
				font-family: 'Fredericka the Great', cursive;
				font-size: 72px;
				padding-left: 115px;
				margin-right: 0;
				// Small devices (tablets, 768px and up)
				@media (min-width: @screen-sm-min) {
					padding-left: 85px;
				}
				// Medium grid
				@media (min-width: @screen-md-min) {
					
				}	
				// Large grid
				@media (min-width: @screen-lg-min) {
					margin-bottom: 242px;
				}
				p {
					font-family: 'Special Elite', cursive;
					font-size: 16px;
					vertical-align: top;
					display: inline-block;
					margin-top: 40px;
					margin-right: 15px;
					span {
						font-size: 20px;
					}
				}
			}
			.img-buy-now {
				-webkit-transition: width 0.3s;
				-moz-transition: width 0.3s;
				-o-transition: width 0.3s;
				transition: width 0.3s;
				width: 80%;
				&:hover {
					width: 85%;
				}
				float: none;
				// Small devices (tablets, 768px and up)
				@media (min-width: @screen-sm-min) {
					float: right;
					width: 70%;
					&:hover {
						width: 75%;
					}
				}
				// Medium grid
				@media (min-width: @screen-md-min) {

				}	
				// Large grid
				@media (min-width: @screen-lg-min) {

				}
			}
			
		}
		&__imageContainer {

			// Small devices (tablets, 768px and up)
			@media (min-width: @screen-sm-min) {
				width: 36%;
				display: block;
				margin: 0 auto;
			}
			// Medium grid
			@media (min-width: @screen-md-min) {
				
			}	
			// Large grid
			@media (min-width: @screen-lg-min) {
				position: absolute;
				left: 30%;
				bottom: 100px;
				width: 32%;
			}
			// Very large grid
			@media (min-width: 1490px) {
				position: absolute;
				left: 30%;
				bottom: 100px;
				width: 32%;
			}
		}
	}	
	&-illustration__outerContainer {
		img {
			width: 100%;
		}
	}
}
.topmost-section {
	padding-top: 100px;
}
/*events page*/
.o-events {
	&__outerContainer {
		background-image: url("../assets/theme/concrete_bg_01.jpg");
		background-size: cover;
		background-position: top center;
		padding: 90px 0 150px 0;
	}
}
/*partners*/
.o-partnersCards {
	&__outerContainer {
		background-image: url("../assets/partners/cards_background.jpg");
		background-size: cover;
		background-position: top center;
		padding-top: 30px;
		padding-bottom: 100px;
		.container {
			padding: 0;
		}
	}
	&-card {
		&__outerContainer {
			padding: 0;
			background-size: 115px;
			background-repeat: no-repeat;
			background-position: center 15px;
			& a {
				display: inline-block;
				width: 100%;
				color: #ffffff;
				background-color: transparent;
				&:hover {
					color: @globalAccent_color1;
					background-color: rgba(255,255,255,.05);
					text-decoration: none;
					p {
						color: @globalAccent_color1;
					}
				}
			}
			&--festivals {
				background-image: url("../assets/partners/card_festivals.png");
			}
			&--airlines {
				background-image: url("../assets/partners/card_airlines.png");
			}
			&--gyms {
				background-image: url("../assets/partners/card_gyms.png");
			}
			&--fair {
				background-image: url("../assets/partners/card_fair.png");
			}
			&--beverage {
				background-image: url("../assets/partners/card_beverage.png");
			}
			&--enterprise {
				background-image: url("../assets/partners/card_enterprise.png");
			}
			&--stations {
				background-image: url("../assets/partners/card_stations.png");
			}
			&--stores {
				background-image: url("../assets/partners/card_stores.png");
			}
			&--cruises {
				background-image: url("../assets/partners/card_cruises.png");
			}
			&--hotels {
				background-image: url("../assets/partners/card_hotels.png");
			}
			&--clubs {
				background-image: url("../assets/partners/card_clubs.png");
			}
			&--transportation {
				background-image: url("../assets/partners/card_transportation.png");
			}
			&--advertising {
				background-image: url("../assets/partners/card_advertising.png");
			}
			&--medical {
				background-image: url("../assets/partners/card_medical.png");
			}
			&--casinos_and_gambling {
				background-image: url("../assets/partners/card_casinos_and_gambling.png");
			}
			&--gaming {
				background-image: url("../assets/partners/card_gaming.png");
			}
			&--media {
				background-image: url("../assets/partners/card_media.png");
			}
			&--police {
				background-image: url("../assets/partners/card_police.png");
			}
			&--students {
				background-image: url("../assets/partners/card_students.png");
			}
		}
		&__innerContainer {
			margin-top: 150px;
			margin-bottom: 30px;
			margin-left: 0;
			margin-right: 0;
			// Small devices (tablets, 768px and up)
			@media (min-width: @screen-sm-min) {
				margin-left: 30px;
				margin-right: 30px;
			}
			// Medium grid
			@media (min-width: @screen-md-min) {

			}	
			// Large grid
			@media (min-width: @screen-lg-min) {

			}
		}
		&__title {
			text-align: center;
			text-transform: uppercase;
			color: #ffffff;	
			font-size: 18px;
		}
		&__text {
			text-align: center;
			color: #959090;
		}
		&__more {
			text-align: center;
			color: #ffffff;
		}
	}
}
/*imprint*/
.o-imprintText {
	&__outerContainer {
		padding-bottom: 150px;
	}
	&__innerContainer {
		// Small devices (tablets, 768px and up)
		@media (min-width: @screen-sm-min) {
			column-count: 2;
			column-gap: 15px
		}
		// Medium grid
		@media (min-width: @screen-md-min) {

		}	
		// Large grid
		@media (min-width: @screen-lg-min) {

		}
	}
}
.o-priavcyText {
	&__outerContainer {
		padding-bottom: 150px;
		padding-top: 50px;
	}
	&__innerContainer {
		// Small devices (tablets, 768px and up)
		@media (min-width: @screen-sm-min) {
			column-count: 2;
			column-gap: 15px
		}
		// Medium grid
		@media (min-width: @screen-md-min) {

		}	
		// Large grid
		@media (min-width: @screen-lg-min) {

		}
	}
}
/*team*/
.o-teamCards-container {
	padding-top: 50px;
	padding-bottom: 100px;
	width: 100%;
	max-width: 1499px;
	display: flex;
	flex-flow: row wrap;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}
.o-teamCard {
	text-align: center;
	width: 250px;
	height: 400px;
	background-size: 500px;
	background-repeat: no-repeat;
	background-position: 0 0;
	padding-top: 1px;
	margin-left: auto;
	margin-right: auto;
	&-inner {
		margin-top: 218px;
	}
	&__name {
		color: #ffffff;
		font-size: 17px;
		font-weight: bold;
		text-transform: uppercase;
	}
	&__titel {
		margin-top: 12px;
		font-weight: bold;
		text-transform: uppercase;
	}
	&:hover {
		background-position: -250px 0;
	}
	&--mark {
		background-image: url("../assets/team_sprites/Mark.png");
	}
	&--anakin {
		background-image: url("../assets/team_sprites/Anna_Annakin.png");
	}
	&--michal {
		background-image: url("../assets/team_sprites/Michael.png");
	}
	&--vince {
		background-image: url("../assets/team_sprites/Vince.png");
	}
	&--piotr {
		background-image: url("../assets/team_sprites/Piotr.png");
	}
	&--katinka {
		background-image: url("../assets/team_sprites/Katinka.png");
	}
	&--rosanna {
		background-image: url("../assets/team_sprites/Rosanna.png");
	}
	&--johanna {
		background-image: url("../assets/team_sprites/Johanna.png");
	}
	&--dominic {
		background-image: url("../assets/team_sprites/Domenic.png");
	}
	&--anna {
		background-image: url("../assets/team_sprites/Anna.png");
	}
	&--laura {
		background-image: url("../assets/team_sprites/Laura.png");
	}
	&--lisa {
		background-image: url("../assets/team_sprites/Lisa.png");
	}	
	&--lotta {
		background-image: url("../assets/team_sprites/Lotta.png");
	}
	&--cathy {
		background-image: url("../assets/team_sprites/Cathy.png");
	}
	&--lena {
		background-image: url("../assets/team_sprites/Lena.png");
	}
	&--christian {
		background-image: url("../assets/team_sprites/Christian.png");
	}
	&--deniz {
		background-image: url("../assets/team_sprites/Deniz.png");
	}
}
// Very Small grid
@media (min-width: 480px) {

}	
// Small devices (tablets, 768px and up)
@media (min-width: @screen-sm-min) {

}
// Medium grid
@media (min-width: @screen-md-min) {

}	
// Large grid
@media (min-width: @screen-lg-min) {

}